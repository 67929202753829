<template>
  <div class="">
    <PageTitle
      title="第三方點數模組參數設定"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({name: 'Parameters'})"
    />
    <ExternalPointDisplaySettings
      v-if="checkActionList([
        'admin.externalPointConfig.singleUpdate',
        'admin.externalPointConfig.singleFind'
      ])"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import ExternalPointDisplaySettings from './components/ExternalPointDisplaySettings.vue'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ExternalPoint',
  components: { PageTitle, ExternalPointDisplaySettings },
  setup (props) {
    const { checkActionList } = usePermissions()

    return {
      checkActionList,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
