<template>
  <div v-loading="loading" class="section-block">
    <SectionTitle title="點數顯示名稱設定" @edit="modal.edit = true" />

    <el-form label-position="left" label-width="200px">
      <el-form-item v-for="field in displayData" :key="field.label" :label="field.label">
        {{ field.value }}
      </el-form-item>
    </el-form>

    <EditExternalPointDisplaySettingsModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { ref, computed, defineComponent, reactive, onBeforeMount } from 'vue'
import EditExternalPointDisplaySettingsModal from './EditExternalPointDisplaySettingsModal.vue'
import { GetExternalPointConfig } from '@/api/externalPoint'
import { externalPointSourceConfig } from '@/config/externalPoint'
import { get } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'ExternalPointDisplaySettings',
  components: { EditExternalPointDisplaySettingsModal },
  setup (props) {
    const { shopId } = useShop()
    const loading = ref(false)
    const configData = ref({})
    const modal = reactive({
      edit: false,
    })

    const displayData = computed(() => {
      return [
        { label: '點數來源', value: get(externalPointSourceConfig, `${get(configData.value, 'source')}.label`, '-') },
        { label: '顯示名稱', value: get(configData.value, 'pointName', '-') },
        { label: '顯示單位', value: get(configData.value, 'unitName', '-') },
      ]
    })

    const refresh = async () => {
      loading.value = true
      const [res, err] = await GetExternalPointConfig({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
    }

    onBeforeMount(async () => {
      refresh()
    })

    return { modal, displayData, configData, loading, refresh }
  },
})
</script>

<style lang="postcss" scoped>

</style>
